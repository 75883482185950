// printDeliveryNote.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const printDeliveryNote = (order, companyDetails) => {
    const doc = new jsPDF();

    // Function to add a delivery note page
    const addDeliveryNotePage = (copyType) => {
        doc.setFontSize(20);
        doc.setFont(undefined, 'bold');
        doc.text(`Delivery Note - ${order.order_number} (${copyType})`, 105, 20, null, null, 'center');

        // Company details table
        doc.autoTable({
            body: [
                [companyDetails.name],
                [companyDetails.address],
                [`Tel: ${companyDetails.phone}`]
            ],
            startY: 30,
            theme: 'plain',
            styles: { fontSize: 10, cellPadding: 1 },
            tableLineColor: [255, 255, 255],
            tableLineWidth: 0,
            showHead: false,
            showFoot: false
        });

        // Customer details table
        const customerDetails = [
            ['Order Number:', order.order_number],
            ['Customer Name:', order.customer.customer_name],
            ['Branch:', order.order_branch.branch_name],
            ['Contact Number:', order.customer.customer_cell],
            ['Delivery Date:', order.order_delivery_date],
            ['Delivery Address:', order.customer.customer_addr_1],
            ['', order.customer.customer_addr_2 ? order.customer.customer_addr_2 : ''],
            ['', order.customer.customer_city ? order.customer.customer_city : ''],
            ['', order.customer.customer_zip ? order.customer.customer_zip : '']
        ];
        doc.autoTable({
            body: customerDetails,
            startY: doc.lastAutoTable.finalY + 10,
            theme: 'plain',
            styles: { fontSize: 10, cellPadding: 1 },
            tableLineColor: [255, 255, 255],
            tableLineWidth: 0,
            showHead: false,
            showFoot: false,
            columnStyles: {
                0: { cellWidth: 50 },
                1: { cellWidth: 'auto' }
            }
        });

        // Item details
        const itemsData = order.line_items.map((item) => [
            item.product_name,
            item.variant ? `${item.variant.variant_set_name} - ${item.variant.variant_name}` : '',
            item.item_qty.toString(),
            item.comments ? item.comments : "",
        ]);
        doc.autoTable({
            head: [['Item', 'Variant', 'Quantity', 'Comments']],
            body: itemsData,
            startY: doc.lastAutoTable.finalY + 5,
            theme: 'grid',
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
            styles: { fontSize: 10, cellPadding: 2 },
        });

        // Acknowledgement table
        const acknowledgementDetails = [
            ['Received in good order by:'],
            ['Name: ______________________________________'],
            ['Signature: _________________________________'],
            ['Date: _______________________________________']
        ];
        doc.autoTable({
            body: acknowledgementDetails,
            startY: doc.lastAutoTable.finalY + 10,
            theme: 'plain',
            styles: { fontSize: 10, cellPadding: 2 },
            tableLineColor: [255, 255, 255],
            tableLineWidth: 0,
            showHead: false,
            showFoot: false
        });

        // Company footer
        const finalY = doc.lastAutoTable.finalY + 15;
        doc.setFontSize(9);
        doc.text(
            `${companyDetails.legalName} T/A ${companyDetails.tradingAs} Reg No. ${companyDetails.regNumber}`,
            105,
            finalY,
            null,
            null,
            'center'
        );
        doc.text(
            companyDetails.physicalAddress,
            105,
            finalY + 5,
            null,
            null,
            'center'
        );

        // Add a new page for the second copy if it's the first copy
        if (copyType === 'Customer Copy') {
            doc.addPage();
        }
    };

    // Add Customer Copy page
    addDeliveryNotePage('Customer Copy');

    // Add Company Copy page
    addDeliveryNotePage('Company Copy');

    // Save the PDF
    doc.save(`Delivery_Note_${order.order_number}.pdf`);
};
