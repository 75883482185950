<template>
    <v-container>
        <v-row dense>
            <v-col>
                <v-card-title>
                    <h1>Delivery Calendar</h1>
                    <v-spacer />
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on">Print Documents <v-icon x-small right>mdi-download</v-icon></v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="openDeliveryDialog('schedule')">
                                <v-list-item-title>Print Delivery Schedule</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openDeliveryDialog('notes')">
                                <v-list-item-title>Print Delivery Notes</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-dialog v-model="deliveryDialogStatus" max-width="400px">
                        <v-card>
                            <v-card-title class="text-h5">Select Date</v-card-title>
                            <v-card-text>
                                <v-date-picker v-model="deliveryDocDate" no-title></v-date-picker>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="deliveryDialogStatus = false">Cancel</v-btn>
                                <v-btn color="primary" text @click="generateDeliveryDocs">Generate</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
            </v-col>
        </v-row>
      <!-- Filters -->
      <v-row>
        <v-col cols="6">
            <v-select
              v-model="selectedMonth"
              :items="months"
              label="Select Month"
              @change="updateCalendar"
              item-text="name"
              item-value="value"
            ></v-select>
        </v-col>
        <v-col cols="6">
            <!-- Branch Filter -->
            <v-select
              v-model="selectedBranch"
              :items="branches"
              label="Select Branch"
              item-text="branch_name"
              item-value="branch_id"
              @change="fetchSalesOrders"
              clearable
            ></v-select>
        </v-col>
      </v-row>
      <!-- Month display -->
      <v-row>
        <v-col cols="12">
            <h4>{{ selectedMonthYear }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-calendar v-model="selectedDate" :events="styledEvents">
              <template v-slot:event="{ event }">
                <div class="event-slot" :style="{ backgroundColor: event.color, fontColor: event.text }">
                    {{ event.name }}
                    <template v-if="event.missingDeliveryTime">
                        <v-icon dark small right>mdi-clock</v-icon>
                    </template>
                    <template v-if="!event.fullPaymentReceived">
                        <v-icon dark small right :color="event.paymentStatusColor">mdi-cash</v-icon>
                    </template>
                    <template v-if="!event.allItemsAssigned">
                        <v-icon dark small right :color="event.productionStatusColor">mdi-hammer-screwdriver</v-icon>
                    </template>
                </div>
            </template>
            </v-calendar>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
    import db from '../../../components/firebaseInit';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';
    import { printDeliveryNote } from '../functions/printDeliveryNote.js';
  
  export default {
    name: 'FulfillmentCalendar',
    data() {
      return {
        selectedDate: new Date().toISOString().substr(0, 10),
        selectedMonth: new Date().getMonth(),
        selectedBranch: null,
        salesOrders: [],
        branches: [],
        months: [
          { name: 'January', value: 0 },
          { name: 'February', value: 1 },
          { name: 'March', value: 2 },
          { name: 'April', value: 3 },
          { name: 'May', value: 4 },
          { name: 'June', value: 5 },
          { name: 'July', value: 6 },
          { name: 'August', value: 7 },
          { name: 'September', value: 8 },
          { name: 'October', value: 9 },
          { name: 'November', value: 10 },
          { name: 'December', value: 11 },
        ],
        deliveryDialogStatus: false,
        deliveryDocumentType: false,
        deliveryDocDate: new Date().toISOString().substr(0, 10),

        companyName: 'Pascal Furniture',
        companyAddress: 'Unit 3, 21 Marconi Road, Monatgue Gardens 7441',
        companyPhone: '079 895 4589',
        companyLegalName: 'Pascal Marlin (Pty) Ltd',
        companyTradingAs: 'Pascal Furniture',
        companyRegNumber: '2020/262421/07',
        companyPhysicalAddress: 'Unit 3, 21 Marconi Road, Monatgue Gardens 7441',
      };
    },
    computed: {
      styledEvents() {
        const today = new Date().toISOString().substr(0, 10);
        return this.salesOrders
            .filter((order) => order.order_delivery_date && new Date(order.order_delivery_date).getMonth() === this.selectedMonth)
            .filter((order) => !this.selectedBranch || order.order_branch.branch_id === this.selectedBranch)
            .map((order) => {
                const isFullyPaid = order.order_value === (order.deposit_recieved + order.balance_recieved);
                const allItemsAssigned = this.calculateProductionStatus(order.line_items) === 'All Items Assigned';
                const isToday = order.order_delivery_date === today;
                const hasDeliveryTime = !!order.order_delivery_time;

                let color = 'lightgreen';
                if (!isFullyPaid || !allItemsAssigned) {
                    color = 'lightcoral';
                }
                if (isToday) {
                    color = isFullyPaid && allItemsAssigned ? 'green' : 'red';
                }

                let eventName = `${order.order_number}`;
                if (hasDeliveryTime) {
                    eventName += ` - ${order.order_delivery_time}`;
                }

                return {
                    name: eventName,
                    start: order.order_delivery_date,
                    end: order.order_delivery_date,
                    color: color,
                    fullPaymentReceived: isFullyPaid,
                    allItemsAssigned: allItemsAssigned,
                    missingDeliveryTime: !hasDeliveryTime,
                    sortKey: hasDeliveryTime ? order.order_delivery_time : '99:99', // Use a high value for missing times
                };
            })
            .sort((a, b) => a.sortKey.localeCompare(b.sortKey)); // Sort based on the sortKey
    },
      selectedMonthYear() {
        const date = new Date(this.selectedDate);
        const monthName = this.months[date.getMonth()].name;
      const year = date.getFullYear();
      return `${monthName} ${year}`;
    },
  },
  methods: {
    fetchSalesOrders() {
      const docRef = db.collection('sales_orders').where('order_status', '==', 'Confirmed');

      try {
        docRef.onSnapshot((snapshot) => {
          this.salesOrders = snapshot.docs.map((doc) => ({ ...doc.data() }));
        });
      } catch (error) {
        console.error('Error fetching sales orders:', error);
      }
    },
    fetchBranchList() {
      const docRef = db.collection('branches').orderBy('branch_name');

      try {
        docRef.onSnapshot((snapshot) => {
          this.branches = snapshot.docs.map((doc) => ({ ...doc.data() }));
        });
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    },
    updateCalendar() {
      const selectedYear = new Date(this.selectedDate).getFullYear();
      const newDate = new Date(Date.UTC(selectedYear, this.selectedMonth, 1));
      this.selectedDate = newDate.toISOString().substr(0, 10);
    },
    calculateProductionStatus(lineItems) {
      const totalItems = lineItems.length;
      const assignedItems = lineItems.filter((item) => item.assigned_to_plan).length;
      return assignedItems === totalItems ? 'All Items Assigned' : 'Not All Items Assigned';
    },
    //
    openDeliveryDialog(type) {
      this.deliveryDialogStatus = true;
      this.deliveryDocumentType = type;
    },
    generateDeliveryDocs() {
        this.deliveryDocumentType === 'schedule' ?
            this.generateDeliverySchedule() :
            this.printDeliveryNotes();
    },
    generateDeliverySchedule() {
        const doc = new jsPDF('landscape');

        let branchName = null;
        // Header with optional branch name
        if (this.selectedBranch) {
            branchName = this.branches.find(branch => branch.branch_id === this.selectedBranch).branch_name;
        }
        const headerText = `Delivery Schedule for ${this.deliveryDocDate}` + (branchName ? ` (${branchName})` : '');
        
        doc.setFontSize(20);
        doc.setFont(undefined, 'bold');
        doc.text(headerText, 148, 30, null, null, 'center');

        const ordersForSelectedDate = this.salesOrders
        .filter((order) =>
            order.order_delivery_date &&
            order.order_delivery_date === this.deliveryDocDate
        )
        .filter((order) => !this.selectedBranch || order.order_branch.branch_id === this.selectedBranch) ;

        const scheduleData = ordersForSelectedDate.map((order) => {
            // Format the customer address based on available fields
            const addressParts = [];
            if (order.customer.customer_addr_1) addressParts.push(order.customer.customer_addr_1);
            if (order.customer.customer_addr_2) addressParts.push(order.customer.customer_addr_2);
            if (order.customer.customer_city) addressParts.push(order.customer.customer_city);
            if (order.customer.customer_zip) addressParts.push(order.customer.customer_zip);
            const formattedAddress = addressParts.join('\n'); // Use newline as separator

            return [
                order.order_number,
                order.customer.customer_name,
                order.customer.customer_cell,
                formattedAddress, // Use the formatted address
                order.order_branch.branch_name,
                '', // Empty columns for dispatch status
            ];
        });

        // Table
        doc.autoTable({
            head: [
            [
                'Order #',
                'Customer Name',
                'Customer Cell',
                'Customer Address',
                'Branch',
                'Dispatched',
            ],
            ],
            body: scheduleData,
            theme: 'grid',
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
            styles: { fontSize: 10, cellPadding: 2 },
            startY: 40
        });

        // Save the PDF
        doc.save(`Delivery_Schedule_${this.deliveryDocDate}.pdf`);
        this.deliveryDialogStatus = false;
        this.deliveryDocumentType = null;
        },
        //
        printDeliveryNotes() {
          const ordersForSelectedDate = this.salesOrders.filter((order) =>
            order.order_delivery_date && order.order_delivery_date === this.deliveryDocDate
          ).filter((order) => !this.selectedBranch || order.order_branch.branch_id === this.selectedBranch);

          const companyDetails = {
            name: this.companyName,
            address: this.companyAddress,
            phone: this.companyPhone,
            legalName: this.companyLegalName,
            tradingAs: this.companyTradingAs,
            regNumber: this.companyRegNumber,
            physicalAddress: this.companyPhysicalAddress,
          };

          ordersForSelectedDate.forEach((order) => {
            printDeliveryNote(order, companyDetails);
          });
        },
  },
  created() {
    this.fetchSalesOrders();
   this.fetchBranchList();
  },
};
</script>

<style scoped>
.event-slot {
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;
  padding: 0;
}
</style>

  